import { useContext, useEffect, useState } from "react";
import { LeadService } from "../services/lead/leadService";
import { Lead } from "../type/lead-type";
import { OfficeService } from "../services/ofiice/officeService";
import { Office } from "../type/office-type";
import { UserService } from "../services/users/userService";
import { User } from "../type/user-type";
import { OperationTypeService } from "../services/operations/operationTypeService";
import { useSelector } from "react-redux";
import { AuthState } from "../redux/authSlice";
import { FeeService } from "../services/fees/feeService";
import { Fee } from "../type/fee-type";
import { Business } from "../type/business-type";
import { ProductTypeService } from "../services/products/productTypeService";
import { ProductTypes } from "../type/product-type";
import { ServiceService } from "../services/services/servicesService";
import { CompanyService } from "../services/companies/companyService";
import { Company } from "../type/company-type";
import { PrivilegeContext } from "../components/priviledge/PriviledgeProvider";
import { BusinessService } from "../services/business/businessService";
import { RoleService } from "../services/auth/roleService";
import { StatusService } from "../services/status/statusService";
import { DocumentTypeService } from "../services/documents/documentTypeService";
import { OriginService } from "../services/origin/originService";
import { OperationService } from "../services/operations/operationService";
import { ClientService } from "../services/clients/clientService";
import { Client } from "../type/client-type";

/**
 * 
 * @EN This function is responsible for fetching the list of leads
 * @ES Esta función se encarga de obtener la lista de leads
 * 
 * @param filters Filter to apply to the list
 * @param noId Return the name instead of the id
 * @returns Fetch the list of leads
 */

export const useLeadList = (filters?: any, noId ?: boolean) => {
    
    const [leadList, setLeadList] = useState([]);

    useEffect(() => {
        const fetchLeadList = async () => {
            const response = await (await (new LeadService()).getLeads(filters)).getResponseData();
            if (response.success) {
                let leadList = response.data.leads.map((lead: Lead) => {
                    return {
                        value: noId ? lead.name : lead.id,
                        label: lead.name,
                        text: lead.name
                    }
                })
                setLeadList(leadList);
            } else {
                setLeadList([]);
            }
        }

        fetchLeadList();
    }, []);

    return leadList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of businesses
 * @ES Esta función se encarga de obtener la lista de negocios
 * 
 * @param filters Filter to apply to the list
 * @param noId Return the name instead of the id
 * @returns Fetch the list of businesses
 */

export const useBusinessList = (filters?: any, noId ?: boolean) => {

    const [businessList, setBusinessList] = useState([]);

    useEffect(() => {
        const fetchBusinessList = async () => {
            const response = await (await (new BusinessService()).getBusinessesForSelect(filters)).getResponseData();
            if (response.success) {
                let businessList = response.data.map((business: any) => {
                    return {
                        value: noId ? business.name : business.id,
                        label: business.name,
                        text: business.name
                    }
                })
                setBusinessList(businessList);
            } else {
                setBusinessList([]);
            }
        }
        
        fetchBusinessList();

    }, []);

    return businessList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of companies
 * @ES Esta función se encarga de obtener la lista de empresas
 * 
 * @param filters
 * @returns Fetch the list of companies
 */

export const useCompanyList = (filters?: any) => {
    
    const [companyList, setCompanyList] = useState([]);

    const { userCan } = useContext(PrivilegeContext)

    useEffect(() => {
        const fetchCompanyList = async () => {
            if (userCan('list', 'companies')) {
                const response = await (await (new CompanyService()).getCompanies(filters)).getResponseData();
                if (response.success) {
                    let companyList = response.data.companies.map((company: Company) => {
                        return {
                            value: company.id,
                            label: company.name,
                            text: company.name
                        }
                    })
                    setCompanyList(companyList);
                } else {
                    setCompanyList([]);
                }
            } else {
                setCompanyList([]);
            }
        }

        fetchCompanyList();
    }, []);

    return companyList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of offices
 * @ES Esta función se encarga de obtener la lista de oficinas
 * 
 * @param filters Filter to apply to the list
 * @param noId Return the name instead of the id
 * @returns Fetch the list of offices
 */

export const useOfficeList = (filters?: any , noId ?: boolean) => {
    const [officeList, setOfficeList] = useState([]);

    useEffect(() => {
        const fetchOfficeList = async () => {
            const response = await (await (new OfficeService()).getOffices(filters)).getResponseData();
            if (response.success) {
                let officeList = response.data.offices.map((office: Office) => {
                    return {
                        value: noId ? office.name : office.id,
                        label: office.name,
                        text: office.name
                    }
                })
                setOfficeList(officeList);
            } else {
                setOfficeList([]);
            }
        }

        fetchOfficeList();
    }, []);

    return officeList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of users
 * @ES Esta función se encarga de obtener la lista de usuarios
 * 
 * @param filters
 * @returns Fetch the list of users
 */

export const useUserList = (filters?: any) => {
    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        const fetchUserList = async () => {
            const response = await (await (new UserService()).getUsers(filters)).getResponseData();
            if (response.success) {
                let usersList = response.data.users.map((user: User) => {
                    return {
                        value: user.id,
                        label: user.name,
                        text: user.name
                    }
                })
                setUsersList(usersList);
            } else {
                setUsersList([]);
            }
        }

        fetchUserList();
    }, []);

    return usersList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of clients
 * @ES Esta función se encarga de obtener la lista de clientes
 * 
 * @param filters Filter to apply to the list
 * @param addNif Add the NIF to the client name
 * @returns Fetch the list of clients
 */

export const useClientList = (filters?: any, addNif? : boolean) => {
    const [clientsList, setClientsList] = useState([]);

    useEffect(() => {
        const fetchClientList = async () => {
            const response = await (await (new ClientService()).getClients(filters)).getResponseData();
            if (response.success) {
                let clientsList = response.data.clients.map((client: Client) => {
                    return {
                        value: client.id,
                        label: addNif ? `${client.name} - ${client.nif}` : client.name,
                        text: client.name
                    }
                })
                setClientsList(clientsList);
            } else {
                setClientsList([]);
            }
        }

        fetchClientList();
    }, []);

    return clientsList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of operations agents
 * @ES Esta función se encarga de obtener la lista de agentes de operaciones
 * 
 * @param filters Filter to apply to the list
 * @param noId Return the name instead of the id
 * @returns Fetch the list of operations agents
 */

export const useOperationAgentsList = (filters?: any, noId ?: boolean) => {
    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        const fetchUserList = async () => {
            const response = await (await (new OperationService()).getOperationAgentsListForSelect(filters)).getResponseData();
            if (response.success) {
                let usersList = response.data.map((user: User) => {
                    return {
                        value: noId ? user.name : user.id,
                        label: user.name,
                        text: user.name
                    }
                })
                setUsersList(usersList);
            } else {
                setUsersList([]);
            }
        }

        fetchUserList();
    }, []);

    return usersList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of operations agents
 * @ES Esta función se encarga de obtener la lista de agentes de operaciones
 * 
 * @param filters Filter to apply to the list
 * @returns Fetch the list of operations agents
 */

export const useOperationAgentsListSimple = (filters?: any) => {
    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        const fetchUserList = async () => {
            const response = await (await (new OperationService()).getOperationAgentsListSimple(filters)).getResponseData();
            if (response.success) {
                let usersList = response.data.users.map((user: User) => {
                    return {
                        value: user.id,
                        label: user.name,
                        text: user.name
                    }
                })
                setUsersList(usersList);
            } else {
                setUsersList([]);
            }
        }

        fetchUserList();
    }, []);

    return usersList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of operation types
 * @ES Esta función se encarga de obtener la lista de tipos de operaciones
 * 
 * @param filters Filter to apply to the list
 * @param noId Return the name instead of the id
 * @returns Fetch the list of operation types
 */

export const useOperationTypeList = (filters?: any , noId ?: boolean) => {
    const [operationTypeList, setOperationTypeList] = useState([]);

    useEffect(() => {
        const fetchOperationTypeList = async () => {
            const response = await (await (new OperationTypeService()).getOperationTypes(filters)).getResponseData();
            if (response.success) {
                let operationTypeList = response.data.operationTypes.map((operationType: any) => {
                    return {
                        value: noId ? operationType.name : operationType.id,
                        label: operationType.name,
                        text: operationType.name
                    }
                })
                setOperationTypeList(operationTypeList);
            } else {
                setOperationTypeList([]);
            }
        }

        fetchOperationTypeList();
    }, []);

    return operationTypeList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of action status for operations
 * @ES Esta función se encarga de obtener la lista de estados de acción para operaciones
 * 
 * @param filters Filter to apply to the list
 * @returns Fetch the list of action status for operations
 */

export const useOperationsActionStatusList = (filters?: any) => {
    const [actionStatusOperationsList, setActionStatusOperationsList] = useState([]);

    useEffect(() => {
        const fetchActionStatusOperationsList = async () => {
            const response = await (await (new StatusService().getActionStatuses({
                filter_filters: { 
                    entity: "all_operations",
                    active : 1 
                },
                all : true
            }))).getResponseData();
            if (response.success) {
                let actionStatusOperationList = response.data.actionStatuses.map((actionStatus: any) => {
                    return {
                        value: actionStatus.id,
                        label: actionStatus.name + ' - ' + actionStatus?.statusEntity?.label,
                        text: actionStatus.name
                    }
                })
                setActionStatusOperationsList(actionStatusOperationList);
            } else {
                setActionStatusOperationsList([]);
            }
        }

        fetchActionStatusOperationsList();
    }, [filters]);

    return actionStatusOperationsList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of action status for operations
 * @ES Esta función se encarga de obtener la lista de estados de acción para operaciones
 * 
 * @returns Fetch the list of action status for operations
 */

export const useActionStatusOperationList = (noActive ?: boolean) => {
    const [actionStatusOperationList, setActionStatusOperationList] = useState([]);

    useEffect(() => {
        const fetchActionStatusOperationList = async () => {
            const active = noActive ? 3 : 1;
            const response = await (await (new StatusService().getActionStatusesSelect({filter_filters: { entity: "operation", active : active }, all : true}))).getResponseData();
            if (response.success) {
                let actionStatusOperationList = response.data.map((actionStatus: any) => {
                    return {
                        value: actionStatus.id,
                        label: actionStatus.name,
                        text: actionStatus.name,
                        color: actionStatus.color,
                        active: actionStatus.active
                    }
                })
                setActionStatusOperationList(actionStatusOperationList);
            } else {
                setActionStatusOperationList([]);
            }
        }

        fetchActionStatusOperationList();
    }, []);

    return actionStatusOperationList;
};


/**
 * 
 * @EN This function is responsible for fetching the list of status for wallets
 * @ES Esta función se encarga de obtener la lista de estados para la cartera
 * 
 * @param filters Filter to apply to the list
 * @returns Fetch the list of action status for wallets
 */

export const useStatusWalletList = (filters?: any) => {
    const [actionStatusWalletList, setActionStatusWalletList] = useState([]);

    useEffect(() => {
        const fetchActionStatusWalletList = async () => {
            const response = await (await (new StatusService().getActionStatuses({filter_filters: { entity: "wallet", active : 1 }, all : true}))).getResponseData();
            if (response.success) {
                let actionStatusWalletList = response.data.actionStatuses.map((actionStatus: any) => {
                    return {
                        value: actionStatus.id,
                        label: actionStatus.name,
                        text: actionStatus.name
                    }
                })
                setActionStatusWalletList(actionStatusWalletList);
            } else {
                setActionStatusWalletList([]); 
            }
        }

        fetchActionStatusWalletList();
    }, []);

    return actionStatusWalletList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of action status for wallets
 * @ES Esta función se encarga de obtener la lista de estados de acción para la cartera
 * 
 * @param filters Filter to apply to the list
 * @returns Fetch the list of action status for wallets
 */

export const useActionStatusWalletList = (filters?: any) => {
    const [actionStatusWalletList, setActionStatusWalletList] = useState([]);

    useEffect(() => {
        const fetchActionStatusWalletList = async () => {
            const response = await (await (new StatusService().getActionStatuses({filter_filters: { entity: "action_wallet", active : 1 }, all : true}))).getResponseData();
            if (response.success) {
                let actionStatusWalletList = response.data.actionStatuses.map((actionStatus: any) => {
                    return {
                        value: actionStatus.id,
                        label: actionStatus.name,
                        text: actionStatus.name
                    }
                })
                setActionStatusWalletList(actionStatusWalletList);
            } else {
                setActionStatusWalletList([]); 
            }
        }

        fetchActionStatusWalletList();
    }, []);

    return actionStatusWalletList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of action status for contracts
 * @ES Esta función se encarga de obtener la lista de estados de acción para contratos
 * 
 * @param filters Filter to apply to the list
 * @returns Fetch the list of action status for contracts
 */

export const useActionStatusContractList = (filters?: any) => {
    const [actionStatusContractList, setActionStatusContractList] = useState([]);

    useEffect(() => {
        const fetchActionStatusContractList = async () => {
            const response = await (await (new StatusService().getActionStatusesSelect({filter_filters: { entity: "contract", active : 1 }, all : true}))).getResponseData();
            if (response.success) {
                let actionStatusContractList = response.data.map((actionStatus: any) => {
                    return {
                        value: actionStatus.id,
                        label: actionStatus.name,
                        text: actionStatus.name
                    }
                })
                setActionStatusContractList(actionStatusContractList);
            } else {
                setActionStatusContractList([]);
            }
        }

        fetchActionStatusContractList();
    }, []);

    return actionStatusContractList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of action status for commissions
 * @ES Esta función se encarga de obtener la lista de estados de acción para comisiones
 * 
 * @param filters Filter to apply to the list
 * @returns Fetch the list of action status for commissions
 */

export const useActionStatusCommissionList = (filters?: any) => {
    const [actionStatusCommissionList, setActionStatusCommissionList] = useState([]);

    useEffect(() => {
        const fetchActionStatusCommissionList = async () => {
            const response = await (await (new StatusService().getActionStatusesSelect({filter_filters: { entity: "commission", active : 1 }, all : true }))).getResponseData();
            if (response.success) {
                let actionStatusLeadList = response.data.map((actionStatus: any) => {
                    return {
                        value: actionStatus.id,
                        label: actionStatus.name,
                        text: actionStatus.name
                    }
                })
                setActionStatusCommissionList(actionStatusLeadList);
            } else {
                setActionStatusCommissionList([]);
            }
        }

        fetchActionStatusCommissionList();
    }, []);

    return actionStatusCommissionList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of services
 * @ES Esta función se encarga de obtener la lista de servicios
 * 
 * @param filters Filter to apply to the list
 * @returns Fetch the list of services
 */

export const useServiceList = (filters?: any) => {
    const [serviceList, setServiceList] = useState([]);
    
    useEffect(() => {
        const fetchServiceList = async () => {
            const response = await (await (new ServiceService()).getServices(filters)).getResponseData();
            if (response.success) {
                let serviceList = response.data.services.map((service: any) => {
                    return {
                        value: service.id,
                        label: service.name,
                        text: service.name
                    }
                }
                )
                setServiceList(serviceList);
            } else {
                setServiceList([]);
            }
        }

        fetchServiceList();
    }, [filters]);

    return serviceList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of fees
 * @ES Esta función se encarga de obtener la lista de tarifas
 * 
 * @param filters Filter to apply to the list
 * @returns Fetch the list of fees
 */

export const useFeeList = (filters?: any) => {
    const [feeList, setFeeList] = useState([]);

    useEffect(() => {
        const fetchFeeList = async () => {
            const response = await (await (new FeeService()).getFees(filters)).getResponseData();
            if (response.success) {
                let feeList = response.data.fees.map((fee: Fee) => {
                    return {
                        value: fee.id,
                        label: fee.name,
                        text: fee.name
                    }
                })
                setFeeList(feeList);
            } else {
                setFeeList([]);
            }
        }

        fetchFeeList();
    }, [filters]);

    return feeList;
};

/**
 * 
 * @EN This function is responsible for fetching the list of product types
 * @ES Esta función se encarga de obtener la lista de tipos de productos
 * 
 * @param filters Filter to apply to the list
 * @returns Fetch the list of product types
 */

export const useProductTypeList = (filters?: any) => {
    const [productTypeList, setProductTypeList] = useState([]);

    useEffect(() => {
        const fetchProductTypeList = async () => {
            const response = await (await (new ProductTypeService()).getProductTypes(filters)).getResponseData();
            if (response.success) {
                let productTypeList = response.data.productTypes.map((productType: any) => {
                    return {
                        value: productType.id,
                        label: productType.name,
                        text: productType.name
                    }
                })
                setProductTypeList(productTypeList);

            } else {
                setProductTypeList([]);
            }
        }

        fetchProductTypeList();

    }, [filters]);

    return productTypeList;
};

/**
 * 
 * @EN This function is responsible for fetching the logged user company
 * @ES Esta función se encarga de obtener la empresa del usuario logueado
 * 
 * @param filters Filter to apply to the list
 * @returns Fetch the logged user company
 */

export const useLoggedUserCompany = () => {
    const [company, setCompany] = useState('');

    const id = useSelector((state: { auth: AuthState }) => {
        const userId = state.auth.user?.id;
        return userId ? userId : '';
    });

    useEffect(() => {
        const fetchCompany = async () => {
            const response = await (await (new UserService()).getUserById(id)).getResponseData();
            if (response.success) {
                setCompany(response.data.companies[0].id);
            } else {
                setCompany('');
            }
        }

        fetchCompany();
    }, [id]);

    return company;
}

/**
 * 
 * @EN This function is responsible for fetching the data of the logged user
 * @ES Esta función se encarga de obtener la información del usuario logueado
 * 
 * @param company Company to apply to the list
 * @returns Fetch the data of the logged user
 */

export const useUserProfile = () => {
    const [user, setUser] = useState<User|null>(null);

    useEffect(() => {
        const fetchCompany = async () => {
            const response = await (await (new UserService()).me()).getResponseData();
            if (response.success) {
                setUser(response.data);
            } else {
                setUser(null);
            }
        }

        fetchCompany();
    }, []);

    return user;
}

/**
 * 
 * @EN This function is responsible for fetching the list of roles
 * @ES Esta función se encarga de obtener la lista de roles
 * 
 * @param company Company to apply to the list
 * @returns Fetch the list of roles
 */

export const useRoleList = (company : string) => {
    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        const fetchRoleList = async () => {
            const response = await (await (new RoleService()).getCompanyRoles(company)).getResponseData();
            if (response.success) {
                let roleList = response.data.roles.map((role: any) => {
                    return {
                        value: role.id,
                        label: role.name,
                        text: role.name
                    }
                })
                setRoleList(roleList);
            } else {
                setRoleList([]);
            }
        }

        fetchRoleList();
    }, [company]);

    return roleList;
}

/**
 * 
 * @EN This function is responsible for fetching the list of document types
 * @ES Esta función se encarga de obtener la lista de tipos de documentos
 * 
 * @param filters Filter to apply to the list
 * @returns Fetch the list of document types
 */

export const useDocumentTypeList = (filters?: any) => {
    const [documentTypeList, setDocumentTypeList] = useState([]);

    useEffect(() => {
        const fetchDocumentTypeList = async () => {
            const response = await (await (new DocumentTypeService().getDocumentTypes(filters ? filters : {filter_filters: { active : 1 },all : true}))).getResponseData(); 
            if (response.success) {
                let documentTypeList = response.data.documentTypes.map((documentType: any) => {
                    return {
                        value: documentType.id,
                        label: documentType.name,
                        text: documentType.name
                    }
                })
                setDocumentTypeList(documentTypeList);
            } else {
                setDocumentTypeList([]);
            }
        }

        fetchDocumentTypeList();
    }, [filters]);

    return documentTypeList;
}

/**
 * 
 * @EN This function is responsible for fetching the list of origins
 * @ES Esta función se encarga de obtener la lista de orígenes
 * 
 * @param filters Filter to apply to the list
 * @param noId Return the name instead of the id
 * @returns Fetch the list of origins
 */

export const useOriginList = (filters?: any , noId ?: boolean) => {
    const [originList, setOriginList] = useState([]);

    useEffect(() => {
        const fetchOriginList = async () => {
            const response = await (await (new OriginService().getOrigins(filters ? filters : {filter_filters: { active : 1 },all : true}))).getResponseData(); 
            if (response.success) {
                let originList = response.data.origins.map((origin: any) => {
                    return {
                        value: noId ? origin.name : origin.id,
                        label: origin.name,
                        text: origin.name
                    }
                })
                setOriginList(originList);
            } else {
                setOriginList([]);
            }
        }

        fetchOriginList();
    }, []);

    return originList;
}

