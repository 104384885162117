import { AxiosResponse } from "axios";
import { EditOperation, NewOperation, OperationHasProducts } from "../../type/operation-type";
import { RestServiceConnection } from "../restServiceConnection";

const OPERATION_ENDPOINT = '/operations';

export class OperationService extends RestServiceConnection {

    createOperation = async (operation: NewOperation ) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/create',
            data:  operation
        }, true);
        return this;
    }

    createOperationV2 = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/create/v2',
            data: values
        }, true);
        return this;
    }

    editOperation = async (operation: EditOperation) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/edit',
            data: operation
        }, true);
        return this;
    }

    editOperationV2 = async (operation: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/edit/v2',
            data: operation
        }, true);
        return this;
    }

    getOperations = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/list',
            data: filters
        }, true) as AxiosResponse;
        return this;
    }

    getComments = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/get-comments`,
            data: filters,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getOperationById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/get',
            data: {
                operation: id
            },
        }, true);
        return this;
    }

    getUserAgentOperations = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/list',
            data: {
                user: id
            },
        }, true);
        return this;
    }

    deleteOperation = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/delete',
            data: {
                operation: id
            },
        }, true);
        return this;
    }

    changeStatus = async (id: string, status: number) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/change-status',
            data: {
                operation: id,
                status: status
            },
        }, true);
        return this;
    }

    changeActionStatus = async (id: string, actionStatus: string, comment: string|null) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/change-action-status',
            data: {
                operation: id,
                action_status: actionStatus,
                comment: comment
            },
        }, true);
        return this;
    }

    exportOperations = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/export',
            data: {
                ...filters,
                all: true
            },
            responseType: 'blob',
        }, true) as AxiosResponse;
        return this;
    }

    addProducts = async (operation: string, products: OperationHasProducts[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/add-products',
            data: {
                operation: operation,
                products: products,
            }
        }, true);
        return this;
    }

    addProduct = async (operation: string, data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/add-product',
            data: {
                operation: operation,
                product: data.product,
                address: data.address,
                town: data.town,
                postalcode: data.postalcode,
                province: data.province,
                cups: data.cups,
                cups_gas: data.cups_gas,
                consumption: data.consumption,
                powerP1: data.powerP1,
                powerP2: data.powerP2,
                powerP3: data.powerP3,
                powerP4: data.powerP4,
                powerP5: data.powerP5,
                powerP6: data.powerP6,
                services : data.services,
            }
        }, true);
        return this;
    }

    removeProduct = async (operation: string, product: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/remove-product',
            data: {
                operation: operation,
                product: product,
            }
        }, true);
        return this;
    }

    editProducts = async (operation: string, data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_ENDPOINT + '/edit-product',
            data: {
                operation: operation,
                product: data.id,
                address: data.address,
                town: data.town,
                postalcode: data.postalcode,
                province: data.province,
                cups: data.cups,
                cups_gas: data.cups_gas,
                consumption: data.consumption,
                powerP1: data.powerP1,
                powerP2: data.powerP2,
                powerP3: data.powerP3,
                powerP4: data.powerP4,
                powerP5: data.powerP5,
                powerP6: data.powerP6,
            }
        }, true);
        return this;
    }

    getCurrentCallData = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: OPERATION_ENDPOINT + '/get-current-call',
        }, true);
        return this;
    }

    getOperationRecords = async (operation: string) => {
        this.response = await this.makeRequest({
            method: 'GET',
            url:  `${OPERATION_ENDPOINT}/records/${operation}`,
        }, true);
        return this;
    }

    getOperationRecordById = async (record: string) => {
        this.response = await this.makeRequest({
            method: 'GET',
            url:  `${OPERATION_ENDPOINT}/records/get/${record}`,
        }, true);
        return this;
    }

    deleteOperationRecord = async (record: string) => {
        this.response = await this.makeRequest({
            method: 'GET',
            url:  `${OPERATION_ENDPOINT}/records/delete/${record}`,
        }, true);
        return this;
    }

    downloadOperationRecord = async (record: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/records/download/${record}`,
        }, true);
        return this;
    }

    addOperationRecord = async (operation: string, data: any) => {
        const formData = new FormData();
        formData.append('name', data.name);
        if(data.callIdentifier) {
            formData.append('callIdentifier', data.callIdentifier);
        }
        if(data.document) {
            formData.append('document', data.document);
        }
        formData.append('operation', operation);

        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/records/add`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);

        return this;
    }

    updateOperationRecord = async (recordId: string, data: any) => {
        const formData = new FormData();
        formData.append('name', data.name);
        if(data.callIdentifier) {
            formData.append('callIdentifier', data.callIdentifier);
        }
        if(data.document) {
            formData.append('document', data.document);
        }
        formData.append('record', recordId); // add record id

        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/records/update`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, true);

        return this;
    }
    
    sendSMS = async (sms_request: string) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/send-sms`,
            data: {
                sms_request
            }
        }, true);

        return this;
    }

    listSMSRequests = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/sms/list`,
            data: filters,
        }, true);

        return this;
    }

    updateSMSRequest = async (data: any ) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/sms/update`,
            data: data,
        }, true);

        return this;
    }

    checkCertificateSMSRequest = async (sms_request: any ) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/sms/get-info-sms`,
            data: {
                sms_request
            },
        }, true);

        return this;
    }

    downloadSMSRequestDocument = async (sms_request: string ) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/sms/download-document`,
            data: {
                sms_request
            },
            responseType: 'blob'
        }, true);

        return this;
    }

    deleteSMSRequest = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/sms/delete`,
            data: {
                sms_request: id
            },
        }, true);

        return this;
    }

    generateSMSRequestDocumentUrl = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/sms/delete`,
            data: {
                sms_request: id
            },
        }, true);

        return this;
    }

    generateSMS = async ({operation}: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/generate-sms`,
            data: {
                operation
            },
        }, true);

        return this;
    }

    updateService = async (operation: string, data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/update-services`,
            data: {
                operation: operation,
                id: data.id,
                services: data.services,
            }
        }, true);
        return this;
    }

    makeOperationDual = async (operation: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${OPERATION_ENDPOINT}/make-dual`,
            data: {
                operation: operation,
            }
        }, true);
        return this;
    }

    deleteComment = async (commentId: string, clientId: string) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/remove-comment`,
            data: {
                operation: clientId,
                comment: commentId
            }
        }, true);

        return this
    }

    updateOperationComment = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/edit-comment`,
            data: data,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    createOperationComment = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/add-comment`,
            data: data,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getStatistics = async (dateFrom : string , dateTo: string , user : string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/get-statistics-between-dates`,
            data: {
                dateFrom: dateFrom,
                dateTo: dateTo,
                user: user
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getRanking = async (dateFrom : string , dateTo: string , agent? : string , office? : string , business? : string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/get-agent-ranking-between-dates`,
            data: {
                filter_filters: {
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    ...(agent ? { agent: agent } : {}),
                    ...(office ? { office: office } : {}),
                    ...(business ? { business: business } : {}),
                }
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getRankingByRanking = async (dateFrom : string , dateTo: string , rankingId? : string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/get-agent-ranking-between-dates-by-ranking`,
            data: {
                rankingId: rankingId,
                filter_filters: {
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                }
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getAgentPointsBetweenDates = async (dateFrom : string , dateTo: string, user? : string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/get-agent-points-between-dates`,
            data: {
                dateFrom: dateFrom,
                dateTo: dateTo,
                user: user ? user : null
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getAgentPendingTasks = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/get-agent-pending-tasks`,
            data:  filters,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getAgentPendingTasksByClient = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/get-agent-pending-tasks-by-client`,
            data:  filters,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    exportContract = async (operationId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/export-operation-to-format`,
            data:  {
                operation: operationId
            },
            responseType: 'blob',
        }, true);
        return this;
    }

    getDocuments = async (operationId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/get-documents`,
            data:  {
                operation: operationId
            },
        }, true);
        return this;
    }

    getDocumentsByDocumentType = async (id: string, documentType: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/get-documents-by-documentType`,
            data: {
                operation: id,
                documentType: documentType
            }
        }, true);
        return this;
    }

    getDocumentsByDocumentTypes = async (id: string, documentTypes: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/get-count-documents-in-documentTypes-by-operation`,
            data: {
                operation: id,
                documentTypes: documentTypes
            }
        }, true);
        return this;
    }

    addOperationDocuments = async (data: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/add-documents`,
            data: data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    getOperationAgentsList = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/list-agents`,
            data: filters
        }, true);
        return this;
    }

    getOperationAgentsListForSelect = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/list-agents-for-select`,
            data: filters
        }, true);
        return this;
    }

    getOperationAgentsListSimple = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/list-agents-simple`,
            data: filters
        }, true);
        return this;
    }

    reApplyCommissionAndPoints = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: `${OPERATION_ENDPOINT}/reapply-points-and-commission`,
            data: {
                operation: id
            }
        }, true);
        return this;
    }

}
