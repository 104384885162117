import { NewActionStatus, Status } from "../../type/actionStatus-type";
import { ActionStatus } from "../../type/actionStatus-type";
import { RestServiceConnection } from "../restServiceConnection";

const STATUS_ENDPOINT = '/statuses';

const ACTION_STATUS_ENDPOINT = '/action-statuses';

const statusServiceCache: any = {};

export class StatusService extends RestServiceConnection {
    getStatuses = async (entity: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATUS_ENDPOINT + '/get-all-by-entity',
            data: {
                entity: entity
            }
        }, true);
        return this;
    }

    getActionStatusById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/get',
            data: {
                action_status: id
            }
        }, true);
        return this;
    }

    getActionStatuses = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/list',
            data: filters,
        }, true);
        return this;
    }

    getActionStatusesSelect = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/list-for-select',
            data: filters,
        }, true);
        return this;
    }

    getChangeActionStatuses = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/list-change',
            data: filters,
        }, true);
        return this;
    }

    getChangeActionStatusesListForSelect = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/list-for-select-change',
            data: filters,
        }, true);
        return this;
    }

    

    getChangeActionStatusesWallet = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/list-change-wallet',
            data: filters,
        }, true);
        return this;
    }

    createActionStatus = async (actionStatus: NewActionStatus) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/create',
            data: actionStatus
        }, true);
        return this;
    }

    editActionStatus = async (actionStatus: ActionStatus) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/edit',
            data: actionStatus
        }, true);
        return this;
    }

    deleteActionStatus = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/delete',
            data: {
                action_status : id
            }
        }, true);
        return this;
    }
    toggleActionStatusActive = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/toggle',
            data: {
                action_status: id,
                active: status
            }
        }, true);
        return this;
    }

    setDefaultInitialStatus = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/set-default',
            data: {
                action_status: id
            }
        }, true);
        return this;
    }

    setApplyCommission = async (id: string, disable : boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/set-apply-commission',
            data: {
                action_status: id,
                disable: disable
            }
        }, true);
        return this;
    }

    setApplyPoints = async (id: string, disable : boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/set-apply-points',
            data: {
                action_status: id,
                disable: disable
            }
        }, true);
        return this;
    }

    setResetCommission = async (id: string, disable : boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/set-reset-commission',
            data: {
                action_status: id,
                disable: disable
            }
        }, true);
        return this;
    }

    setResetPoints = async (id: string, disable : boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTION_STATUS_ENDPOINT + '/set-reset-points',
            data: {
                action_status: id,
                disable: disable
            }
        }, true);
        return this;
    }

    getEntityTypes = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: ACTION_STATUS_ENDPOINT + '/get-all-entity-types',
        }, true);
        return this;
    }
}

