import { RestServiceConnection } from "../restServiceConnection";

const BUSINESS_ENDPOINT = '/businesses';

export class BusinessService extends RestServiceConnection {
    
    createBusiness = async (business: FormData, hasImg: boolean) => {
        const businessObj: any = {};
        business.forEach((value, key) => {
            if(value === '' && (key == 'clawback_days' || key == 'renewal_days')){businessObj[key] = 0}
            else if(value == ''){businessObj[key] = null}
            else (businessObj[key] = value)});
        businessObj['logo'] = null;
        
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUSINESS_ENDPOINT + '/create',
            data: hasImg ? business : businessObj,
            headers: {
                "Content-Type": hasImg ? "application/x-www-form-urlencoded" : "application/json"
            }
        }, true);
        return this;
    }

    editBusiness = async (business: any, hasImg: boolean) => {
        const businessObj: any = {};
        business.forEach((value: any, key: any) => {
            if(value === '' && (key == 'clawback_days' || key == 'renewal_days')){businessObj[key] = 0}
            else if(value === ''){businessObj[key] = null}
            else (businessObj[key] = value)
        });
        businessObj['logo'] = null;
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUSINESS_ENDPOINT + '/edit',
            data: hasImg ? business : businessObj,
            headers: {
                "Content-Type": hasImg ? "application/x-www-form-urlencoded" : "application/json"
            }
        }, true);
        return this;
    }

    getBusinesses = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUSINESS_ENDPOINT + '/list',
            data: filters ? {...filters} : {},
        }, true);
        return this;
    }

    getBusinessesForSelect = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUSINESS_ENDPOINT + '/list-for-select',
            data: filters ? {...filters} : {},
        }, true);
        return this;
    }

    getBusinessById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: BUSINESS_ENDPOINT + `/get/${id}`
        }, true);
        return this;
    }

    toggleBusinessStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: BUSINESS_ENDPOINT + '/toggle',
            data: {
                business: id,
                active: status
            },
        }, true);
        return this;
    }


    deleteBusiness = async (businessId: string) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url: BUSINESS_ENDPOINT + '/delete',
            data: {
                business: businessId
            }
        }, true);

        return this
    }
}